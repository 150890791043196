export enum AddressTypes {
  ADDRESS = "ADDRESS",
  ARCHIVE = "ARCHIVE",
  ARCHIVE_STORAGE = "ARCHIVE_STORAGE",
  CABINET = "CABINET",
  FLOOR = "FLOOR",
  HOUSE = "HOUSE",
  ROOM = "ROOM",
  SHELF = "SHELF",
  SHELVING = "SHELVING",
}
