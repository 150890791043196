
























































import { defineComponent } from "@vue/composition-api";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { AddressTypes } from "@monorepo/catalog/src/views/StorageView/constants/addressTypes";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

export default defineComponent({
  name: "StorageTreeItem",
  components: {
    SelectFilter,
  },
  data() {
    return {
      AddressTypes,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("storageCatalogView", ["types", "archives"]),
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_DELETE);
    },
    typesObj(): Record<string, string> {
      return this.types.reduce((acc: Record<string, string>, item: { value: string; title: string }) => {
        acc[item.value] = item.title;
        return acc;
      }, {});
    },
    itemIcon(): (type: string) => string {
      return (type: string) => {
        let svgName = "";
        switch (type) {
          case AddressTypes.ARCHIVE:
            svgName = "archive";
            break;
          case AddressTypes.ADDRESS:
            svgName = "address";
            break;
          case AddressTypes.ARCHIVE_STORAGE:
            svgName = "storage";
            break;
          case AddressTypes.CABINET:
            svgName = "cabinet";
            break;
          case AddressTypes.FLOOR:
            svgName = "floor";
            break;
          case AddressTypes.HOUSE:
            svgName = "house";
            break;
          case AddressTypes.ROOM:
            svgName = "room";
            break;
          case AddressTypes.SHELF:
            svgName = "shelf";
            break;
          case AddressTypes.SHELVING:
            svgName = "shelving";
            break;
        }
        return `${environmentVariables.BASE_URL}assets/images/storage/${svgName}.svg`;
      };
    },
  },
  methods: {
    deleteElementCb() {
      if (!this.item.id) {
        this.$emit("delete");
        return;
      }
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => this.$emit("delete"),
          title: `Удалить элемент?`,
        })
      );
    },
    addElementCb() {
      this.$emit("add");
    },
    onChange(key: string, value: string) {
      this.$emit("update", {
        ...this.item,
        [key]: value,
      });
    },
  },
});
