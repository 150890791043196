



























import Vue from "vue";
import { defineComponent } from "@vue/composition-api";
import StorageTreeItem from "@monorepo/catalog/src/views/StorageView/components/StorageTreeItem.vue";
import { mapActions, mapGetters } from "vuex";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export default defineComponent({
  name: "StorageTree",
  components: {
    StorageTreeItem,
    InfiniteScroll,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: [],
      active: [],
      treeLoading: false,
      infiniteId: new Date().toString(),
      treeItemsObj: {} as Record<string, any>,
      selectedIds: {} as Record<string, boolean>,
    };
  },
  computed: {
    ...mapGetters("storageCatalogView", ["types"]),
    typesCodes(): Record<string, number> {
      return this.types.reduce((res: Record<string, number>, current: { code: string; level: number }) => {
        res[current.code] = current.level;
        return res;
      }, {});
    },
    typesLevels(): Record<string, string> {
      return this.types.reduce((res: Record<string, string>, current: { code: string; level: number }) => {
        res[current.level] = current.code;
        return res;
      }, {});
    },
    treeItems(): Record<string, unknown>[] {
      return (this.treeItemsObj.main || []).map(this.getTreeItems);
    },
  },
  methods: {
    ...mapActions("storageCatalogView", ["getTreeData", "saveTreeItem", "deleteElement"]),
    getTreeItems(node: any) {
      return {
        ...node,
        ...(node.id && node.type !== "SHELF" ? { children: (this.treeItemsObj[node.id] || []).map(this.getTreeItems) } : {}),
      };
    },
    async fetchTreeChildren(item: { children: unknown[]; id: string | number; type: string }) {
      const { data } = await this.getTreeData({
        parentNodeId: item.id,
      });
      return (data || []).map((treeItem: Record<string, unknown>) => {
        return {
          ...treeItem,
          parentId: item.id,
          ...(treeItem.type === "SHELF"
            ? {}
            : {
                children: [],
              }),
        };
      });
    },
    async fetchChildren(item: { children: unknown[]; id: string | number; type: string }) {
      if ((this.treeItemsObj[item.id || "main"] || []).length) {
        return;
      }
      const children = await this.fetchTreeChildren(item);
      Vue.set(this.treeItemsObj, item.id || "main", children);
      if (!children.length) {
        this.addDefaultChildItem(item);
      }
    },
    deleteTreeItem(item: any) {
      const idField = item.id ? "id" : "key";
      const result = (this.treeItemsObj[item.parentId || "main"] || []).filter((tmpItem: any) => tmpItem[idField] !== item[idField]);
      Vue.set(this.treeItemsObj, item.parentId || "main", result);
      if (!result.length) {
        this.addNewItem(item);
      }
    },
    async deleteElementCb(item: any) {
      if (!item.id) {
        this.deleteTreeItem(item);
        return;
      }
      const isDeleted = await this.deleteElement(item.id);
      if (isDeleted) {
        showNotification("Элемент успешно удалён", NOTIFICATION_STATUS.SUCCESS);
        this.deleteTreeItem(item);
      }
    },
    updateTreeItem(item: any, tmpKey?: string) {
      const newItems = (this.treeItemsObj[item.parentId || "main"] || []).map((treeItem: any) => {
        const isProperElement = item.id && !tmpKey ? treeItem.id === item.id : treeItem.key === (item.key || tmpKey);
        if (!isProperElement) {
          return treeItem;
        }
        return { ...item };
      });
      Vue.set(this.treeItemsObj, item.parentId || "main", newItems);
    },
    async updateItem(item: any) {
      this.updateTreeItem(item);
      if (!item.type || (item.type !== "ARCHIVE" && !item.value) || (item.type === "ARCHIVE" && !item.archiveId)) {
        return;
      }
      const resultItem = await this.saveTreeItem({
        id: item.id,
        data: {
          type: item.type,
          value: item.type === "ARCHIVE" ? item.archiveId : item.value,
          ...(item.parentId ? { parentNodeId: item.parentId } : {}),
          ...(item.archiveId ? { archiveId: item.archiveId } : {}),
        },
      });
      if (resultItem && !item.id) {
        this.updateTreeItem(
          {
            ...resultItem,
            parentId: item.parentId,
            ...(resultItem.type === "SHELF"
              ? {}
              : {
                  children: [],
                }),
          },
          item.key
        );
      }
    },
    addNewItem(item: any) {
      const result = [
        ...(this.treeItemsObj[item.parentId || "main"] || []),
        { parentId: item.parentId, key: `${Math.random()}-key`, type: item.type || "", value: "" },
      ];
      Vue.set(this.treeItemsObj, item.parentId || "main", result);
    },
    addDefaultChildItem(parent: any) {
      const newLevel = parent.type ? (this.typesCodes[parent.type] || 0) + 1 : 0;
      const result = [
        ...(this.treeItemsObj[parent.id] || []),
        { parentId: parent.id, key: `${Math.random()}-key`, type: this.typesLevels[newLevel] || "", value: "" },
      ];
      Vue.set(this.treeItemsObj, parent.id || "main", result);
    },
    handleTreeData() {
      this.treeLoading = true;
      return this.getTreeData().then(({ data }) => {
        const resultMainItems = (data || []).map((item: any) => ({ ...item, children: [] }));
        if (!resultMainItems.length) {
          resultMainItems.push({
            type: "ARCHIVE",
            parentId: null,
            archiveId: "",
            value: "",
          });
        }
        Vue.set(this.treeItemsObj, "main", resultMainItems);
        this.treeLoading = false;
        return { data: this.treeItems };
      });
    },
  },
});
